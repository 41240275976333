import React from 'react';

import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Seo from '../components/seo';

import Layout from '../components/layout';

const TechTalks = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulBlogPost(sort: { fields: [publishedDate], order: DESC }) {
        nodes {
          title
          slug
          postTags
          postAuthor
          postAuthorTitle
          publishedDate(formatString: "MMM Do, YYYY")
          postImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              aspectRatio: 1.5
              width: 416
              height: 210
            )
          }
        }
      }
    }
  `);

  const Posts = data.allContentfulBlogPost.nodes;

  return (
    <Layout>
      <Seo title="Tech Talk" />
      <div className="font-futura">
        <section className="content-block flex flex-wrap relative w-full md:py-75 bg-cover bg-fixed bg-default-pattern text-secondary after:content after:w-full after:h-full after:absolute after:left-0 after:top-0 after:z-0 after:bg-overlay after:mix-blend-lighten">
          <div className="container xl:px-[3.3rem] mx-auto px-4 pt-7rem relative z-2">
            <h2 className="w-full block mb-md font-semibold text-[2.5rem] md:text-[3.5rem] leading-normal md:leading-1.3">Tech Talks</h2>
            <h5 className="mb-md  text-base md:text-[1.4rem]">We distill the Trends, Thoughts and Technologies.</h5>
          </div>
        </section>

        <section className="blog flex-flex-wrap w-full md:py-50px">
          <div className="container xl:px-[3rem]  mx-auto">
            <div className="flex flex-wrap galleryWrapper">
              {Posts.map((post) => (
                <div key={post.slug} className="flex flex-col lg:w-4/12 md:w-6/12 px-2">
                  <div className="md:my-25px w-full block float-left my-7 bg-secondary shadow-md flex-1">
                    <figure className="">
                      <Link to={`/TechTalks/${post.slug}`} alt="post link" rel="preload">
                        <GatsbyImage image={post.postImage.gatsbyImageData} className="image w-full h-175px" alt="news" />
                      </Link>
                    </figure>
                    <div className="py-wide px-lg flex flex-wrap m-0 w-full">
                      <div className="flex flex-wrap mb-4">
                        {post.postTags.map((tag) => (
                          <div className="mr-3" key={tag}>
                            <p className="bg-gray-50 p-2 md:text-16px lg:text-14px">{tag}</p>
                          </div>
                        ))}
                      </div>
                      <h4 className="tracking-1 leading-1.75rem block text-md-1 mb-md">
                        <Link className="text-body hover:text-overlay" to={`/TechTalks/${post.slug}`} alt="post title" rel="preload">{post.title}</Link>
                      </h4>
                      <figcaption className="inline-block">
                        <h6 className="mb-5 font-medium mb-md text-md-1">
                          {`By ${post.postAuthor}`}
                        </h6>
                        <small className="font-light opacity-60">
                          {`${post.postAuthorTitle}, Assutech`}
                        </small>
                      </figcaption>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default TechTalks;
